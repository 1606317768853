import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import Card from 'components/Card';
import { Trans, useTranslation } from 'react-i18next';
import ToolTip from 'components/ToolTip';
import { Job, JobNegotiableSections, JobPost } from 'models/Job';
import { Currency } from 'constants/currency';
import { FormApi } from 'final-form';
import { FormJobArbitration } from 'models/Job/Arbitration';
import { getArbitrationChanges, isPendingChanges } from 'utils/job-changes';
import WarningIcon from 'icons/Warning.icon';
import JobChanges from 'components/JobChanges';

import './ArbitrationSetUp.styles.scss';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import ReactPlayer from 'react-player';
import Accordion from 'components/Accordion';

type ArbitrationSetUpProps = {
  className?: string;
  collapsable?: boolean;
  jobPrice: number;
  valuesFromForm: Pick<
    JobPost<'form'>,
    'arbitrationOptions' | 'hasArbitration'
  > & {
    currency: Currency;
  };
  formApi: FormApi<JobPost<'form'>>;
  disabled?: boolean;
  skillOptions: OptionValue[];
  initialArbitration: FormJobArbitration[];
  getLockButton: (sectionName: JobNegotiableSections) => JSX.Element;
} & Pick<Job, 'prevVersion'> &
  Required<Pick<Job, 'versionState' | 'isLockedArbitration'>>;

const ArbitrationSetUp: React.FC<ArbitrationSetUpProps> = (props) => {
  const {
    className,
    valuesFromForm,
    formApi,
    prevVersion,
    skillOptions,
    versionState,
    isLockedArbitration,
    collapsable = false,
  } = props;

  const { t } = useTranslation();

  const { hasArbitration: formHasArbitration, arbitrationOptions = [] } =
    valuesFromForm;

  const hasArbitration = formHasArbitration === 'true';

  const classes = classNames('anys-arbitration-set-up', className);

  const messages = useMemo(
    () => ({
      title: t('General.arbitration'),
      noArbitration: t('ArbitrationSetUp.paragraphNo'),
      selectArbitrators: t('ArbitrationSetUp.selectArbitrators'),
      suggestedArbitrators: t('ArbitrationSetUp.suggestedArbitrators'),
      addFilterModalTitle: t('ArbitrationSetUp.addFilterModalTitle'),
    }),
    [t],
  );

  const timeAndPricing = formApi.getState().values.timeAndPricing;

  useEffect(() => {
    if (!timeAndPricing?.price?.amount) {
      return;
    }
    const price = timeAndPricing.price.amount / 100;

    if (timeAndPricing.type === 'By project') {
      arbitrationOptions[0].arbitratorsNumber = price <= 1000 ? 3 : 5;
    } else {
      // hourly
      if (timeAndPricing.pricingDurationType === 'Strict') {
        const total = price * timeAndPricing.totalHours;
        arbitrationOptions[0].arbitratorsNumber = total <= 1000 ? 3 : 5;
      }
      if (timeAndPricing.pricingDurationType === 'Flexible') {
        const total =
          price * timeAndPricing.totalHours * timeAndPricing.maxHours;
        arbitrationOptions[0].arbitratorsNumber = total <= 1000 ? 3 : 5;
      }
    }

    formApi.change('arbitrationOptions', arbitrationOptions);
  }, [arbitrationOptions, formApi, timeAndPricing]);

  const arbitrationChanges =
    prevVersion && isPendingChanges(versionState)
      ? getArbitrationChanges(
          t,
          skillOptions,
          {
            hasArbitration: prevVersion.hasArbitration,
            arbitrationOptions: prevVersion.arbitrationOptions,
          },
          {
            hasArbitration,
            arbitrationOptions,
          },
          prevVersion.isLockedArbitration,
          isLockedArbitration,
          false,
        )
      : null;

  const accordionConfig = {
    showIcon: collapsable,
    disabled: !collapsable,
    defaultExpanded: !collapsable,
  };

  const AccordionHeader = () => {
    return (
      <div className="anys-arbitration-set-up__title">
        {messages.title}
        {arbitrationChanges ? (
          <ToolTip
            t={t}
            tooltipName="arbitration-changes"
            icon={WarningIcon}
            className="anys-arbitration-set-up__title__changes"
          >
            <JobChanges
              changedFrom={arbitrationChanges.changedFrom}
              changedTo={arbitrationChanges.changedTo}
            />
          </ToolTip>
        ) : null}
        <div className="anys-arbitration-set-up__row">
          <ToolTip t={t} tooltipName="arbitration-preview-tooltip">
            <ReactPlayer width="100%" url="https://youtu.be/i5sjEtiy23g" />
            <p style={{ whiteSpace: 'pre-line' }}>
              <Trans
                i18nKey="Preview.arbitration"
                // eslint-disable-next-line jsx-a11y/heading-has-content
                components={{ b: <b />, hb: <h3 /> }}
              />
            </p>
          </ToolTip>
        </div>
      </div>
    );
  };

  return (
    <Card className={classes}>
      <Accordion
        className={classes}
        header={AccordionHeader()}
        {...accordionConfig}
      >
        <p
          className={classNames(
            'anys-arbitration-set-up__explanation',
            'anys-arbitration-set-up__explanation--desktop',
          )}
        >
          {messages.noArbitration}
        </p>
      </Accordion>
    </Card>
  );
};
export default ArbitrationSetUp;
