import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import './Accordion.styles.scss';
import DownIcon from '../../icons/Down.icon';

type AccordionProps = {
  children: React.ReactNode;
  header?: React.ReactNode;
  className?: string;
  maxHeight?: string;
  showIcon?: boolean;
  defaultExpanded?: boolean;
  disabled?: boolean;
};

const Accordion: React.FC<AccordionProps> = ({
  children,
  className,
  defaultExpanded,
  header,
  maxHeight,
  showIcon,
  disabled,
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultExpanded);
  const [contentHeight, setContentHeight] = useState<number>(0);

  const contentRef = useRef<HTMLDivElement>(null);

  const classes = classNames('anys-accordion', className, {
    'anys-accordion--open': isOpen,
    'anys-accordion--disabled': disabled,
  });

  const updateContentHeight = () => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  };

  useEffect(() => {
    updateContentHeight();
  }, [isOpen, children]);

  const toggleAccordion = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (disabled) {
      return;
    }
    const target = e.target as HTMLElement;

    const isInteractive =
      target.closest('button, a, [role="button"], [tabindex], input, label') !==
      null;

    if (isInteractive) {
      return;
    }
    setIsOpen(!isOpen);
  };

  return (
    <div className={classes}>
      <div
        className="anys-accordion__accordion-header"
        onClick={toggleAccordion}
      >
        {header ? (
          <h2 className="anys-accordion__accordion-title">{header}</h2>
        ) : null}
        {showIcon ? (
          <div className="anys-accordion__accordion-icon">
            <DownIcon
              showGradient
              className={`arrow ${isOpen ? 'rotate' : ''}`}
            />
          </div>
        ) : null}
      </div>
      <div
        className="anys-accordion__accordion-body"
        style={{
          maxHeight: isOpen ? maxHeight || `${contentHeight}px` : '0px',
        }}
      >
        <div ref={contentRef} className="accordion-content">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
