import React, { useMemo, useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from 'ncoded-component-library';
import { useLocation, useNavigate } from 'react-router-dom';
import useCallbackRef from 'hooks/useCallbackRef';
import Modal, { ModalRef } from 'components/Modal';
import EndContractModal from '../EndContractModal';
import RequestPaymentModal from '../RequestPaymentModal';
import api from 'api';
import { useContract } from '../../../Contract/Contract.page';
import dayjs from 'dayjs';
import StickyBottomContent from 'components/StickyBottomContent';
import useInboxLink from 'router/subrouters/Inbox/hooks/useInboxLink';
import { Contract } from 'models/Contract';
import Notice from 'components/Notice';
import InfoCircleIcon from 'icons/InfoCircle.icon';
import JobPreviewPrice from 'components/JobPreviewPrice';
import confirm from 'modules/confirm';
import useModalCloseOveride from 'hooks/useModalCloseOveride';

import './ContractPreviewActions.styles.scss';
import './ContractPreviewActions.styles.responsive.scss';
import { isPendingChanges } from 'utils/job-changes';
import { calcJobPrice } from 'utils/job';
import { parseTimeAndPricing } from 'utils/job-parses';
import { DropdownRef } from 'ncoded-component-library/build/components/molecules/Dropdown/Dropdown.component';
import DropdownOptions from 'components/DropdownOptions';
import ElipsisIcon from 'icons/Elepsis.icon';
import { DropdownOption } from 'components/DropdownOptions/DropdownOptions.component';

type JobPreviewActionsProps = {
  className?: string;
  contract: Contract;
  isCurrentUserClient: boolean;
  hasClientSigned: boolean;
  hasProviderSigned: boolean;
  maxCancelationFee?: number;
  isOwnContract: boolean;
  isClientPaymentPending: boolean;
  onConfirmChanges: () => void;
};

const ContractPreviewActions: React.FC<JobPreviewActionsProps> = (props) => {
  const {
    className,
    contract,
    isCurrentUserClient,
    maxCancelationFee,
    isOwnContract,
    isClientPaymentPending,
    onConfirmChanges,
  } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setContract, rejectChanges, confirmOrRejectInProgress } =
    useContract();
  const location = useLocation();

  const [isSingleItem, setIsSingleItem] = useState(false);

  const { createEntityLink } = useInboxLink();

  const {
    commonId: contractId,
    timeAndPricing,
    versionSubmitedBy,
    versionState,
    prevVersion,
    isNegotiable,
    freeCancelation: cancelationPolicy,
    prevAcceptedVersion,
  } = contract || {};

  const classes = classNames('anys-contract-preview-actions', className);

  const isCancelationPR = useRef(false);

  const [endContractModal, endContractModalRef] =
    useCallbackRef<ModalRef>(null);

  const [requestPaymentModal, requestPaymentModalRef] =
    useCallbackRef<ModalRef>(null);

  const isInbox = location.pathname?.startsWith('/inbox');

  const inCancelationTime = useMemo(
    () =>
      dayjs(timeAndPricing?.startDate)
        .subtract(cancelationPolicy?.cancelationHours, 'hours')
        .diff(dayjs()) < 0
        ? true
        : false,
    [cancelationPolicy?.cancelationHours, timeAndPricing?.startDate],
  );

  const messages = useMemo(
    () => ({
      change: t('General.change'),
      reject: t('General.reject'),
      changeRequestPending: t('General.changeRequestPending'),
      confirmChangeRequest: t('General.confirmRequest'),
      confirmPayment: t('General.clientContractConfirmPayment'),
      confirmPaymentCta: t('General.confirmPayment'),
    }),
    [t],
  );

  const contractEditLink = isInbox
    ? createEntityLink('edit', 'contract', contractId)
    : `/contract/edit/${contractId}`;

  const hasClientMadeChanges = versionSubmitedBy === 'Client';
  const hasProviderMadeChanges = versionSubmitedBy === 'Provider';

  const showClientMadeChanges =
    prevVersion && versionState === 'Pending' && hasClientMadeChanges;

  const showProviderMadeChanges =
    prevVersion && isPendingChanges(versionState) && hasProviderMadeChanges;

  const canShowConfirmOrRejectChanges =
    isClientPaymentPending ||
    (!!prevVersion &&
      ((!!isCurrentUserClient && !!showProviderMadeChanges) ||
        (!isCurrentUserClient && !!showClientMadeChanges)));

  const pricePreview = <JobPreviewPrice job={contract} isOffer />;

  const endContractButton = (
    <Button
      type="button"
      variant="outline"
      onClick={() => {
        endContractModal.open();
      }}
    >
      <span>{t('Contract.endContract')}</span>
    </Button>
  );

  const confirmChangesButton = (
    <Button
      type="button"
      onClick={onConfirmChanges}
      disabled={confirmOrRejectInProgress}
    >
      <span>
        {isClientPaymentPending
          ? messages.confirmPaymentCta
          : messages.confirmChangeRequest}
      </span>
    </Button>
  );

  const dropdownRef = useRef<DropdownRef>();

  const handleOnClickChangeContract = async () => {
    if (isNegotiable || isOwnContract) {
      navigate(contractEditLink);
      return;
    }
    await confirm({
      title: t('General.entityNonNegotiable', {
        entity: t('General.contract'),
      }),
      cancelContent: null,
    });
  };

  const addByCondition = (condition: boolean, item: DropdownOption) => {
    return condition ? [item] : [];
  };

  const clientDropDownOptions = [
    ...addByCondition(canShowConfirmOrRejectChanges, {
      title: messages.reject,
      key: messages.reject,
      onClick: async () => {
        ((dropdownRef as any).current as any)?.setIsOpen(false);
        await rejectChanges();
      },
      disabled: confirmOrRejectInProgress,
    }),

    ...addByCondition(!canShowConfirmOrRejectChanges, {
      title: messages.change,
      key: messages.change,
      onClick: async () => {
        ((dropdownRef as any).current as any)?.setIsOpen(false);
        await handleOnClickChangeContract();
      },
    }),
  ];

  const providerDropDownOptions: DropdownOption[] = [
    ...addByCondition(canShowConfirmOrRejectChanges, {
      title: messages.change,
      key: messages.change,
      onClick: async () => {
        ((dropdownRef as any).current as any)?.setIsOpen(false);
        await handleOnClickChangeContract();
      },
    }),

    ...addByCondition(
      cancelationPolicy && inCancelationTime && !canShowConfirmOrRejectChanges,
      {
        title: t('Contract.sendCancellationPaymentRequest'),
        key: t('Contract.sendCancellationPaymentRequest'),
        onClick: async () => {
          ((dropdownRef as any).current as any)?.setIsOpen(false);
          isCancelationPR.current = false;
          requestPaymentModal.open();
        },
      },
    ),
    ...addByCondition(canShowConfirmOrRejectChanges, {
      title: messages.reject,
      key: messages.reject,
      onClick: async () => {
        ((dropdownRef as any).current as any)?.setIsOpen(false);
        await rejectChanges();
      },
      disabled: confirmOrRejectInProgress,
    }),
    {
      title: t('Contract.endContract'),
      key: t('Contract.endContract'),
      onClick: () => endContractModal.open(),
    },
  ];

  const dropDownActionOptions = isCurrentUserClient
    ? clientDropDownOptions
    : providerDropDownOptions;

  useEffect(() => {
    if (dropDownActionOptions.length === 1) {
      setIsSingleItem(true);
    }
  }, [dropDownActionOptions]);

  const ActionsDropDown = (
    <DropdownOptions
      className={`actions-dropdown ${isSingleItem ? 'dropdown--single-item' : ''}`}
      dropdownRef={dropdownRef}
      lockScroll={false}
      trigger={
        <Button
          className="actions-dropdown trigger-button"
          variant="link"
          icon={<ElipsisIcon />}
        />
      }
      options={dropDownActionOptions}
    />
  );

  const isAwaitingPayment = versionState === 'Awaiting payment';

  const currentPrice = timeAndPricing
    ? calcJobPrice(parseTimeAndPricing(timeAndPricing)) / 100
    : 0;

  const prevAcceptedPrice = prevAcceptedVersion?.timeAndPricing
    ? calcJobPrice(parseTimeAndPricing(prevAcceptedVersion.timeAndPricing)) /
      100
    : 0;

  const maxPrice = isAwaitingPayment ? prevAcceptedPrice : currentPrice;

  useModalCloseOveride(requestPaymentModal);

  return (
    <>
      <StickyBottomContent className={classes}>
        {isClientPaymentPending && (
          <Notice className="anys-contract-preview-actions__notice">
            <InfoCircleIcon /> {messages.confirmPayment}
          </Notice>
        )}
        {showProviderMadeChanges || showClientMadeChanges ? (
          <Notice className="anys-contract-preview-actions__notice">
            <InfoCircleIcon /> {messages.changeRequestPending}
          </Notice>
        ) : null}
        {isCurrentUserClient ? (
          <div className="anys-contract-preview-actions__buttons">
            {canShowConfirmOrRejectChanges
              ? confirmChangesButton
              : endContractButton}
            {ActionsDropDown}
            {pricePreview}
          </div>
        ) : (
          <div className="anys-contract-preview-actions__buttons">
            {canShowConfirmOrRejectChanges ? (
              confirmChangesButton
            ) : (
              <Button
                type="button"
                variant={'solid'}
                onClick={() => {
                  isCancelationPR.current = false;
                  requestPaymentModal.open();
                }}
              >
                {t('Contract.sendPaymentRequest')}
              </Button>
            )}
            {ActionsDropDown}
            {pricePreview}
          </div>
        )}
      </StickyBottomContent>

      <EndContractModal
        modal={endContractModalRef}
        isCurrentUserClient={isCurrentUserClient}
        cancelationPolicy={cancelationPolicy}
        onClose={() => {
          endContractModal.close();
        }}
      />

      <Modal
        ref={requestPaymentModalRef}
        type="no-action"
        modalName="requstPayment"
        onOverlayClose={() => undefined}
        renderAsPortal={false}
      >
        <RequestPaymentModal
          maxAmountFee={
            isCancelationPR.current
              ? +maxCancelationFee.toFixed(2)
              : +maxPrice.toFixed(2)
          }
          hasReview={!isCancelationPR.current}
          title={t('Contract.requestPayment')}
          initialValue={isCancelationPR.current ? maxCancelationFee : maxPrice}
          onConfirm={async (values) => {
            try {
              const { data } = await api.contract.requestPayment(contractId, {
                isCancellation: isCancelationPR.current,
                rating: !isCancelationPR.current ? values.mark : undefined,
                content: values.mark < 5 ? values.explanation : undefined,
                price: +values.cancelationFee * 100,
              });

              setContract((old) => ({
                ...old,
                paymentProposal: data.contract.paymentProposal,
              }));
              requestPaymentModal.close();
            } catch (e) {
              console.error(e);
            }
          }}
          onCancel={() => {
            requestPaymentModal.close();
          }}
        />
      </Modal>
    </>
  );
};

export default ContractPreviewActions;
