import React, { useCallback, useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import Card from 'components/Card';
import ToolTip from 'components/ToolTip';
import Avatar from 'components/Avatar';
import { Button, Loader } from 'ncoded-component-library';
import { Trans, useTranslation } from 'react-i18next';
import MiniAvatar from 'components/MiniAvatar';
import { formatUserDisplayName } from 'utils/user';
import { User } from 'models/User';
import { Link, useNavigate } from 'react-router-dom';
import JobPostSummaryPayModal from 'router/subrouters/JobPost/pages/JobPostSummaryPayModal';
import useCallbackRef from 'hooks/useCallbackRef';
import { ModalRef } from 'components/Modal';

import './JobSign.styles.scss';
import './JobSign.styles.responsive.scss';
import ReactPlayer from 'react-player';
import CurrentUserContext from '../../providers/CurrentUser/CurrentUser.context';
import Accordion from 'components/Accordion';

type JobSignProps = {
  className?: string;
  isClientSignDisabled: boolean;
  isProviderSignDisabled: boolean;
  firstAvatar?: 'provider' | 'client';
  isCurrentUserClient: boolean;
  hasClientSigned: boolean;
  hasProviderSigned: boolean;
  provider: Partial<User>;
  client: Partial<User>;
  jobId: number;
  inProgress?: boolean;
  isForm?: boolean;
  handleSign: (signed: boolean) => Promise<void | any>;
  onSign?: (signed: boolean) => Promise<void>;
  collapsable?: boolean;
};

const JobSign: React.FC<JobSignProps> = (props) => {
  const {
    className,
    isClientSignDisabled,
    isProviderSignDisabled,
    firstAvatar = 'provider',
    hasClientSigned,
    hasProviderSigned,
    isCurrentUserClient,
    provider,
    client,
    jobId,
    inProgress,
    isForm,
    handleSign,
    onSign,
    collapsable = false,
  } = props;

  const navigate = useNavigate();
  const { currentUser } = useContext(CurrentUserContext);
  const { t } = useTranslation();

  const [jobPostSummaryModalRef, jobPostSummaryModalSetRef] =
    useCallbackRef<ModalRef>(null);

  const messages = useMemo(
    () => ({
      sign: t('General.sign'),
      provider: t('General.provider'),
      client: t('General.client'),
      notSigned: t('General.notSigned'),
      signed: t('General.signed'),
      reserve: t('General.reserve'),
      takeJob: t('General.takeJob'),
    }),
    [t],
  );

  const isSigned =
    !isForm && jobId >= 0 && (hasProviderSigned || hasClientSigned);

  const classes = classNames(
    'anys-job-sign',
    { 'anys-job-sign--client-first': firstAvatar === 'client' },
    { 'anys-job-sign--signed': isSigned },
    className,
  );

  const [jobPostSummaryModal, setJobPostSummaryModal] = useState();

  const getSignButton = useCallback(
    (isProviderSign = false) => {
      const hasSigned = isProviderSign ? hasProviderSigned : hasClientSigned;
      const isDisabled = isProviderSign
        ? isProviderSignDisabled
        : isClientSignDisabled;

      const canProviderSign = isProviderSign && !isCurrentUserClient;
      const canClientSign = !isProviderSign && isCurrentUserClient;

      const actionContent = isForm
        ? messages.sign
        : hasProviderSigned && !isProviderSign
          ? messages.reserve
          : hasClientSigned && isProviderSign
            ? messages.takeJob
            : messages.sign;

      if (!hasSigned && (canProviderSign || canClientSign)) {
        return (
          <Button
            type="button"
            variant="solid"
            onClick={async () => {
              if (!currentUser?.id) {
                return navigate('/auth');
              }

              const [hasError, jobPost] = await handleSign(hasSigned);

              if (!hasError) {
                setJobPostSummaryModal(jobPost);

                jobPostSummaryModalRef.open();
              }
            }}
            disabled={isDisabled || inProgress}
            className="anys-job-sign__field__action"
          >
            {inProgress ? (
              <Loader inline overlay={false} size="small" />
            ) : (
              actionContent
            )}
          </Button>
        );
      }

      return (
        <div
          className={classNames('anys-job-sign__field__non-action', {
            'anys-job-sign__field__non-action--signed': hasSigned,
            'anys-job-sign__field__non-action--not-signed': !hasSigned,
          })}
        >
          {hasSigned ? messages.signed : messages.notSigned}
        </div>
      );
    },
    [
      hasProviderSigned,
      hasClientSigned,
      isProviderSignDisabled,
      isClientSignDisabled,
      isCurrentUserClient,
      isForm,
      messages.sign,
      messages.reserve,
      messages.takeJob,
      messages.signed,
      messages.notSigned,
      inProgress,
      currentUser?.id,
      handleSign,
      navigate,
      jobPostSummaryModalRef,
    ],
  );

  const providerSignButton = useMemo(
    () => getSignButton(true),
    [getSignButton],
  );
  const clientSignButton = useMemo(() => getSignButton(), [getSignButton]);

  const accordionConfig = {
    showIcon: collapsable,
    disabled: !collapsable,
    defaultExpanded: !collapsable,
  };

  const AccordionHeader = () => {
    return (
      <div className="anys-job-sign__title">
        {messages.sign}
        <div className="anys-job-sign__row">
          <ToolTip t={t} tooltipName="sign-tooltip">
            <ReactPlayer width="100%" url="https://youtu.be/9tt5nqZS2RM " />
            <p style={{ whiteSpace: 'pre-line' }}>
              <Trans i18nKey="JobForm.signTooltip" components={{ b: <b /> }} />
            </p>
          </ToolTip>
        </div>
      </div>
    );
  };

  return (
    <Card className={classes}>
      <Accordion
        className={classes}
        header={AccordionHeader()}
        {...accordionConfig}
      >
        <div className="anys-job-sign__field">
          <label>{messages.provider}</label>
          <div className="anys-job-sign__field__avatar-wrapper">
            {isSigned ? (
              <MiniAvatar
                id={provider?.id}
                userName={formatUserDisplayName(provider, messages.provider)}
                profileImage={provider?.profileImage}
                rating={provider?.overallSkillScore?.averageRating}
                votes={provider?.overallSkillScore?.numberOfReviews}
                isSkillReview
                info={provider?.profession || ' '}
                className={classNames({
                  'anys-job-sign__field__avatar-wrapper__no-user': !provider,
                  'anys-job-sign__field__avatar-wrapper__no-reviews':
                    !provider?.overallSkillScore?.numberOfReviews,
                })}
                profileLinkProps={{ target: '_blank' }}
              />
            ) : provider?.id ? (
              <Link to={`/profile/${provider.id}`} target="_blank">
                <Avatar
                  img={provider?.profileImage?.url}
                  className="anys-job-sign__field__avatar-wrapper__avatar"
                />
              </Link>
            ) : (
              <Avatar
                img={provider?.profileImage?.url}
                className="anys-job-sign__field__avatar-wrapper__avatar"
              />
            )}
            {providerSignButton}
          </div>
        </div>
        <div className="anys-job-sign__field">
          <label>{messages.client}</label>
          <div className="anys-job-sign__field__avatar-wrapper">
            {isSigned ? (
              <MiniAvatar
                id={client?.id}
                userName={formatUserDisplayName(client, messages.client)}
                profileImage={client?.profileImage}
                rating={client?.overallClientScore?.averageRating}
                votes={client?.overallClientScore?.numberOfReviews}
                info={client?.profession || ' '}
                isSkillReview={false}
                className={classNames({
                  'anys-job-sign__field__avatar-wrapper__no-user': !client,
                  'anys-job-sign__field__avatar-wrapper__no-reviews':
                    !client?.overallClientScore?.numberOfReviews,
                })}
                profileLinkProps={{ target: '_blank' }}
              />
            ) : client?.id ? (
              <Link to={`/profile/${client.id}`} target="_blank">
                <Avatar
                  img={client?.profileImage?.url}
                  className="anys-job-sign__field__avatar-wrapper__avatar"
                />
              </Link>
            ) : (
              <Avatar
                img={client?.profileImage?.url}
                className="anys-job-sign__field__avatar-wrapper__avatar"
              />
            )}

            {clientSignButton}
          </div>
        </div>

        <JobPostSummaryPayModal
          summaryType={isCurrentUserClient ? 'client' : 'provider'}
          onClose={() => jobPostSummaryModalRef.close()}
          name={'job-sign-summary-pay-modal'}
          isInForm={true}
          jobPostForm={jobPostSummaryModal}
          handleSign={onSign}
          jobPostSummaryModalRef={jobPostSummaryModalSetRef}
        />
      </Accordion>
    </Card>
  );
};

export default JobSign;
