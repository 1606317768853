import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import Card from 'components/Card';
import { TFunction } from 'i18next';
import ToolTip from 'components/ToolTip';
import AddIcon from 'icons/Add.icon';
import { Button } from 'ncoded-component-library';
import FileInputField from 'components/FileInputField';
import { Picture, NonImageFile } from 'models/User';
import RedWarningIcon from 'icons/RedWarning.icon';
import GradientText from 'components/GradientText';
import { Job } from 'models/Job';
import { getAttachmentChanges, isPendingChanges } from 'utils/job-changes';
import WarningIcon from 'icons/Warning.icon';
import JobChanges from 'components/JobChanges';
import Accordion from 'components/Accordion';

import './AddAttachments.styles.scss';

type AddAttachmentsProps = {
  className?: string;
  disabled?: boolean;
  t: TFunction;
  valuesFromForm: (NonImageFile | Picture | File)[];
  onRemoveFile: (file: NonImageFile | Picture | File, index: number) => void;
  collapsable?: boolean;
} & Pick<Job, 'versionState' | 'prevVersion'>;

const AddAttachments: React.FC<AddAttachmentsProps> = (props) => {
  const {
    className,
    t,
    valuesFromForm,
    disabled,
    versionState,
    prevVersion,
    onRemoveFile,
    collapsable = false,
  } = props;

  const classes = classNames('anys-add-attachments', className);

  const files = valuesFromForm && Array.from(valuesFromForm);

  const hasFiles = files?.length > 0;

  const totalFileSize = files.reduce((accumulator, obj) => {
    return accumulator + +obj.size / 1024 / 1024;
  }, 0);

  const messages = useMemo(
    () => ({
      title: t('General.attachments'),
      add: t('JobForm.addAttachments'),
      noAttachmentsPlaceholder: t('JobForm.noAttachmentsPlaceholder'),
      allowedFileTypes: t('JobForm.allowedFileTypes'),
      remove: t('General.remove'),
      files: t('General.files'),
    }),
    [t],
  );

  const fileInputDisabled =
    files.length >= 5 || totalFileSize >= 15 || disabled;

  const totalFileSizeOverLimit = totalFileSize > 15;

  const formAttachments = valuesFromForm?.length
    ? valuesFromForm.map((attch) => {
        if ('url' in attch)
          return {
            name: attch.name,
            url: attch.url,
            originalFileId: attch.originalFileId,
          };

        return { name: attch.name, url: URL.createObjectURL(attch) };
      })
    : null;

  const attachmentChanges =
    prevVersion && isPendingChanges(versionState)
      ? getAttachmentChanges(
          prevVersion.attachments?.map((attch) => ({
            name: attch.name,
            url: attch.url,
            originalFileId: attch.originalFileId,
          })),
          formAttachments,
        )
      : null;

  useEffect(() => {
    if (!formAttachments?.length) return;

    return () => {
      formAttachments?.forEach((attch) => {
        URL.revokeObjectURL(attch.url);
      });
    };
  }, [formAttachments]);

  const accordionConfig = {
    showIcon: collapsable,
    disabled: !collapsable,
    defaultExpanded: !collapsable,
  };

  const AccordionHeader = () => {
    return (
      <>
        <div className="anys-add-attachments__title">
          {messages.title}
          {attachmentChanges ? (
            <ToolTip
              t={t}
              tooltipName="attachment-changes"
              icon={WarningIcon}
              className="anys-add-attachments__title__changes"
            >
              <JobChanges
                changedFrom={attachmentChanges.changedFrom}
                changedTo={attachmentChanges.changedTo}
              />
            </ToolTip>
          ) : null}
          <div className="anys-add-attachments__row">
            <ToolTip t={t} tooltipName="attachments-tooltip">
              <p style={{ whiteSpace: 'pre-line' }}>
                {t('Preview.attachments')}
              </p>
            </ToolTip>
          </div>
        </div>
      </>
    );
  };

  return (
    <Card className={classes}>
      <Accordion
        className={classes}
        header={AccordionHeader()}
        {...accordionConfig}
      >
        <div
          className={classNames(
            'anys-add-attachments__explanation',
            'anys-add-attachments__explanation--desktop',
          )}
        >
          {messages.allowedFileTypes}
        </div>
        <p
          className={classNames(
            'anys-add-attachments__explanation',
            'anys-add-attachments__explanation--mobile',
          )}
        >
          {messages.allowedFileTypes}
        </p>
        {hasFiles ? (
          <>
            <ul className="anys-add-attachments__files">
              {files?.map((el, i) => (
                <li
                  key={el.name + i}
                  className="anys-add-attachments__files__file"
                >
                  <span className="anys-add-attachments__files__file__name">
                    {el.name}
                  </span>
                  <div className="anys-add-attachments__files__file__remove">
                    <span>{`${(+el.size / 1024 / 1024).toFixed(1)} MB`}</span>
                    <Button
                      type="button"
                      variant="link"
                      styleType="secondary"
                      onClick={() => onRemoveFile(el, i)}
                      disabled={disabled}
                    >
                      <span>{messages.remove}</span>
                    </Button>
                  </div>
                </li>
              ))}
            </ul>
            <div className="anys-add-attachments__progres-bar">
              <span className="anys-add-attachments__progres-bar__number-of-files">
                {`${
                  files.length <= 5 ? files.length : 5
                } / 5 ${messages.files.toLowerCase()}`}
              </span>
              <div className="anys-add-attachments__progres-bar__total-file-size">
                {totalFileSizeOverLimit && <RedWarningIcon />}
                <span
                  className={classNames(
                    'anys-add-attachments__progres-bar__total-file-size__size',
                    {
                      'anys-add-attachments__progres-bar__total-file-size__size--error':
                        totalFileSizeOverLimit,
                    },
                  )}
                >
                  {`${totalFileSize.toFixed(1)} MB / 15 MB`}
                </span>
              </div>
            </div>
          </>
        ) : (
          <span className="anys-add-attachments__no-attachments">
            {messages.noAttachmentsPlaceholder}
          </span>
        )}
        <div className="anys-add-attachments__file-uploader">
          <FileInputField
            name="attachments"
            multiple
            accept=".jpg,.png,.pdf,.docx,.doc"
            disabled={fileInputDisabled}
            className="anys-add-attachments__file-input"
            maxFiles={5}
            trigger={
              <div
                className={classNames(
                  'anys-add-attachments__file-input__trigger',
                  {
                    'anys-add-attachments__file-input__trigger--disabled':
                      fileInputDisabled,
                  },
                )}
              >
                <AddIcon /> <GradientText> {messages.add}</GradientText>
              </div>
            }
          />
        </div>
      </Accordion>
    </Card>
  );
};

export default AddAttachments;
