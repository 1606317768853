import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  SetStateAction,
} from 'react';
import { Arbitration } from 'models/Arbitrations';
import ArbitrationListChip from './components/ArbitrationListChip/ArbitrationListChip.component';
import { Button, Loader } from 'ncoded-component-library';
import classNames from 'classnames';
import { t } from 'i18next';
import useQueryParams from 'hooks/useQueryParams';
import useInfiniteContainer from 'hooks/useInfiniteContainer';
import useInfinitePagination from 'hooks/useInfinitePagination';
import api from 'api';
import { PaginationParams } from 'models/Pagination';
import useEvent from 'hooks/useEvent';
import { useArbitration } from 'providers/Arbitration/Arbitration.provider';
import NotificationsContext from 'providers/Notifications/Notifications.context';
import useMatchMedia from 'hooks/useMatchMedia';

import './ArbitrationsList.styles.scss';
import './ArbitrationsList.styles.responsive.scss';
import Tabs from 'router/subrouters/Profile/pages/ProfilePreview/components/AvailabilityModal/components/Tabs';

const ArbitrationsList = () => {
  const { readManyNotifications } = useContext(NotificationsContext);
  const [active, setActive] = useState(1);

  const {
    setArbitrations,
    setArbitrationView,
    selectedArbitration,
    setSelectedArbitration,
    arbitrationView,
  } = useArbitration();

  const {
    params: { arbitrationId, arbitration_view },
    setQueryParam,
    removeSeveralQueryParams,
  } = useQueryParams();

  const getArbitrations = useCallback(
    async (currentPage: number, take: number) => {
      try {
        if (arbitrationView === 'my_cases') {
          const queryParams: PaginationParams = {
            $relations: 'contract,notifications',
            $take: take,
            $page: currentPage,
            $order: '-updatedAt',
            // $where:
            //   '{ "state": { "IN": ["pending_funds","pending_proofs", "finding_arbiters", "decisioning", "pending_funds_provider_won"] } }',
          };

          const {
            data: { items, totalItems, totalPages },
          } = await api.arbitration.getArbitrationsAsDefendant(queryParams);
          setArbitrations(items);

          if (arbitrationId) {
            return {
              items: [selectedArbitration, ...items],
              totalItems,
              totalPages,
            } as const;
          } else return { items, totalItems, totalPages } as const;
        } else {
          const queryParams: PaginationParams = {
            $relations: 'contract,notifications',
            $take: take,
            $page: currentPage,
            $order: '-createdAt',
            // $where: '{ "invites.state": { "IN": ["invited", "accepted"] } }',
            // $where: '{ "state": { "NOT": "finding_arbiters" } }',
          };

          const {
            data: { items, totalItems, totalPages },
          } = await api.arbitration.getArbitrationsAsArbiter(queryParams);

          setArbitrations(items);

          if (arbitrationId) {
            return {
              items: [selectedArbitration, ...items],
              totalItems,
              totalPages,
            } as const;
          } else return { items, totalItems, totalPages } as const;
        }
      } catch (e) {
        console.error(e);
      }
    },
    [arbitrationId, arbitrationView, selectedArbitration, setArbitrations],
  );

  const {
    loading,
    onContainerScrolled,
    items: arbitrationItems,
    setItems: setArbitrationItems,
    setCurrentPage,
  } = useInfinitePagination<Arbitration>({
    take: 12,
    makeRequest: getArbitrations as any,
    debounceTime: 300,
    uniqByProp: 'id',
  });

  useEffect(() => {
    setArbitrationItems([]);
    setCurrentPage(1);
  }, [arbitration_view, setArbitrationItems, setCurrentPage]);

  const isPhablet = useMatchMedia('isPhablet');

  const { onScroll } = useInfiniteContainer({
    container: isPhablet
      ? window
      : document.getElementById('anys-arbitration-list'),
    onScroll: onContainerScrolled,
    loading: loading,
    loader: (
      <Loader
        className="anys-chat-main__spinner"
        overlay={false}
        size="small"
      />
    ),
  });

  useEvent(window, 'scroll', onScroll, undefined, isPhablet);

  useEffect(() => {
    if (!selectedArbitration || !selectedArbitration?.notifications?.length)
      return;

    const readNotifs = async () => {
      const readSuccess = await readManyNotifications(
        'arbitration',
        selectedArbitration.id,
        selectedArbitration.notifications.map(({ id }) => id),
      );

      if (!readSuccess) return;

      setArbitrations((old) =>
        old.map((arb) =>
          arb.id === selectedArbitration.id
            ? { ...arb, notifications: [] }
            : arb,
        ),
      );

      setArbitrationItems((old) =>
        old.map((arb) =>
          arb?.id === selectedArbitration?.id
            ? { ...arb, notifications: [] }
            : arb,
        ),
      );
    };

    readNotifs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArbitration, setArbitrationItems, setArbitrations]);

  const classes = classNames('anys-arbitration-list');

  const availabilityTabs: { name: string; id: number }[] = [
    {
      name: t('Arbitration.myCases'),
      id: 1,
    },
    {
      name: t('Arbitration.meAsArbitrator'),
      id: 2,
    },
  ];

  const handleOnSelectArbitrationView = (tabindex: SetStateAction<number>) => {
    const arbitrationViewType = tabindex === 1 ? 'my_cases' : 'me_as_arbitor';
    setArbitrationView(arbitrationViewType);
    setQueryParam('arbitration_view', arbitrationViewType);
    removeSeveralQueryParams(['arbitrationId', 'my_terms']);
    setSelectedArbitration(undefined);
    setActive(tabindex);
  };

  return (
    <div className={classes}>
      <div className="anys-arbitration-list__title">
        <h1>{t('General.arbitration')}</h1>
        <div>
          <Button
            variant="link"
            onClick={() => {
              removeSeveralQueryParams(['arbitrationId', 'arbitration_view']);
              setQueryParam('my_terms', 'true');
            }}
          >
            <span>{t('Arbitration.professionalArbitration')}</span>
          </Button>
        </div>
      </div>
      <Tabs
        labels={availabilityTabs}
        active={active}
        setActive={handleOnSelectArbitrationView}
        className="anys-arbitration-list__tabs"
      />
      <section
        className="anys-arbitration-list__items"
        id="anys-arbitration-list"
        onScroll={onScroll}
      >
        {arbitrationItems?.map(
          (arbitration: Arbitration) =>
            arbitration && (
              <ArbitrationListChip
                key={arbitration.id}
                arbitration={arbitration}
              />
            ),
        )}
      </section>
    </div>
  );
};

export default ArbitrationsList;
