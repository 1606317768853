import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import Card from 'components/Card';
import RadioGroupField from 'components/RadioGroupField';
import {
  JOB_SERVICE_TYPES,
  JOB_TYPE_OF_SERVICE_LOCATION_TYPE,
} from 'constants/job';
import utils from 'utils';
import {
  FormJobTypeOfService,
  JobServiceTypes,
  JobTypeOfServiceLocationType,
} from 'models/Job/TypeOfService';
import ToolTip from 'components/ToolTip';
import { Job, JobNegotiableSections, JobPost } from 'models/Job';
import SelectField from 'components/SelectField';
import { FormApi } from 'final-form';
import TimelineLinePart from 'components/Timeline/components/TimelineLinePart';
import InputAutocompleteLocationField from 'components/InputAutocompleteLocation/InputAutocompleteLocationField.component';
import formValidators from 'utils/formValidators';
import { TFunction } from 'i18next';
import type { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import { useTranslation } from 'react-i18next';
import { getTypeOfServiceChanges, isPendingChanges } from 'utils/job-changes';
import WarningIcon from 'icons/Warning.icon';
import JobChanges from 'components/JobChanges';
import InputField from 'components/InputField';

import './TypeOfService.styles.scss';
import { parseFormTypeOfService, parseTypeOfService } from 'utils/job-parses';
import Accordion from 'components/Accordion';

const MIN_AREA_FT = 100;
const MAX_AREA_FT = 100000;

type TypeOfServiceProps = {
  className?: string;
  t: TFunction;
  getLockButton: (sectionName: JobNegotiableSections) => JSX.Element;
  collapsable?: boolean;
} & Pick<Job, 'prevVersion'> &
  LocationFieldsProps<JobPost<'form'>> &
  Required<Pick<Job, 'versionState' | 'isLockedTypeOfService'>>;

const TypeOfService: React.FC<TypeOfServiceProps> = (props) => {
  const {
    t,
    className,
    prevVersion,
    getLockButton,
    versionState,
    isLockedTypeOfService,
    collapsable = false,
    ...fieldsProps
  } = props;

  const classes = classNames('anys-type-of-service', className);

  const messages = useMemo(
    () => ({
      title: t('JobForm.typeOfService'),
    }),
    [t],
  );

  const lockButton = useMemo(
    () => getLockButton('isLockedTypeOfService'),
    [getLockButton],
  );

  const typeOfServiceChanges =
    prevVersion && isPendingChanges(versionState)
      ? getTypeOfServiceChanges(
          t,
          // Do a double parse to remove invalid props
          parseFormTypeOfService(parseTypeOfService(prevVersion.typeOfService)),
          parseFormTypeOfService(fieldsProps.valuesFromForm),
          prevVersion.isLockedTypeOfService,
          isLockedTypeOfService,
        )
      : null;

  const accordionConfig = {
    showIcon: collapsable,
    disabled: !collapsable,
    defaultExpanded: !collapsable,
  };

  const AccordionHeader = () => {
    return (
      <div className="anys-type-of-service__title">
        {messages.title}
        {typeOfServiceChanges ? (
          <ToolTip
            t={t}
            tooltipName="type-of-service-changes"
            icon={WarningIcon}
            className="anys-type-of-service__title__changes"
          >
            <JobChanges
              changedFrom={typeOfServiceChanges.changedFrom}
              changedTo={typeOfServiceChanges.changedTo}
            />
          </ToolTip>
        ) : null}
        <div className="anys-type-of-service__row">
          {lockButton}
          <ToolTip t={t} tooltipName="type-of-service-tooltip">
            <p>{t('Preview.typeOfService')}</p>
          </ToolTip>
        </div>
      </div>
    );
  };

  return (
    <Card className={classes}>
      <Accordion
        className={classes}
        header={AccordionHeader()}
        {...accordionConfig}
      >
        <LocationFields {...fieldsProps} />
      </Accordion>
    </Card>
  );
};

type LocationFieldsProps<T = any> = {
  valuesFromForm: FormJobTypeOfService;
  initialTypeOfService: FormJobTypeOfService;
  formApi: FormApi<T>;
  disabled?: boolean;
  withValidation?: boolean;
  withBounds?: boolean;
  withPlaceId?: boolean;
  withFullAddress?: boolean;
};

export const LocationFields: React.FC<LocationFieldsProps> = (props) => {
  const {
    valuesFromForm,
    initialTypeOfService,
    formApi,
    disabled,
    withValidation = true,
    withBounds,
    withPlaceId,
    withFullAddress,
  } = props;
  const { t } = useTranslation();
  const { type } = valuesFromForm || {};

  const isOffline = type === 'Offline';

  const isOneSpot = isOffline && valuesFromForm.locationType === 'One spot';
  const isDirection = isOffline && valuesFromForm.locationType === 'Direction';

  const messages = useMemo(
    () => ({
      locationType: t('General.locationType'),
      location: t('General.location'),
      locationPlaceholder: t('General.locationPlaceholder'),
      startLocation: t('General.startLocation'),
      endLocation: t('General.endLocation'),
      area: t('General.area'),
      areaPlaceholder: t('JobForm.areaPlaceholder'),
      locationRequired: t('JobForm.locationRequired'),
      startLocationRequired: t('JobForm.startLocationRequired'),
      areaRequired: t('JobForm.areaRequired'),
      endLocationRequired: t('JobForm.endLocationRequired'),
      radius: t('General.radius'),
      radiusPlaceholder: t('General.radiusPlaceholder'),
      valueRequired: t('General.valueRequired'),
    }),
    [t],
  );

  const onChangeTypeOfService = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const locationType = event.target.value as JobServiceTypes;

      if (locationType === 'Online') {
        const idMaybe = valuesFromForm?.id ? { id: valuesFromForm.id } : {};

        formApi.change('typeOfService', {
          type: locationType,
          ...idMaybe,
        } as FormJobTypeOfService);
      } else {
        const idMaybe = valuesFromForm?.id ? { id: valuesFromForm.id } : {};

        const isInitialTypeOffline = initialTypeOfService?.type === 'Offline';

        formApi.change('typeOfService', {
          type: locationType,
          ...(isInitialTypeOffline
            ? initialTypeOfService
            : {
                locationType: JOB_TYPE_OF_SERVICE_LOCATION_TYPE.ONE_SPOT,
              }),
          ...idMaybe,
        } as FormJobTypeOfService);
      }
    },
    [formApi, initialTypeOfService, valuesFromForm],
  );

  const onChangeLocationType = useCallback(
    (value: string | OptionValue) => {
      const val = value as JobTypeOfServiceLocationType;

      let newTypeOfService: FormJobTypeOfService;

      const { locationType } = initialTypeOfService || {};

      switch (val) {
        case 'One spot':
          newTypeOfService = {
            ...valuesFromForm,
            ...(locationType === 'One spot'
              ? initialTypeOfService
              : {
                  startLocation: null,
                  endLocation: null,
                  area: null,
                }),
            locationType: val,
          };

          formApi.change('typeOfService', newTypeOfService);
          break;

        case 'Direction':
          newTypeOfService = {
            ...valuesFromForm,
            ...(locationType === 'Direction'
              ? initialTypeOfService
              : {
                  startLocation: null,
                  endLocation: null,
                  area: null,
                }),
            locationType: val,
          };

          formApi.change('typeOfService', newTypeOfService);
          break;

        case 'Area':
          newTypeOfService = {
            ...valuesFromForm,
            ...(locationType === 'Area'
              ? initialTypeOfService
              : {
                  startLocation: null,
                  endLocation: null,
                  area: null,
                }),
            locationType: val,
          };

          formApi.change('typeOfService', newTypeOfService);
          break;

        default:
          break;
      }
    },
    [formApi, initialTypeOfService, valuesFromForm],
  );

  const typeOfServiceOptions = useMemo(
    () =>
      Object.values(JOB_SERVICE_TYPES).map((type) => ({
        label: t(`General.${utils.camelize(type)}`),
        value: type,
      })),
    [t],
  );

  const locationTypeOptions = useMemo(
    () =>
      Object.values(JOB_TYPE_OF_SERVICE_LOCATION_TYPE).map((type) => ({
        label: t(`General.${utils.camelize(type)}`),
        value: type,
      })),
    [t],
  );

  const firstLocationLabel = isOneSpot
    ? messages.location
    : isDirection
      ? messages.startLocation
      : messages.area;

  const firstLocationPlaceholder =
    !isDirection && !isOneSpot
      ? messages.areaPlaceholder
      : messages.locationPlaceholder;

  const firstLocationValidation = isOneSpot
    ? messages.locationRequired
    : isDirection
      ? messages.startLocationRequired
      : messages.areaRequired;

  return (
    <>
      <RadioGroupField
        name="typeOfService.type"
        options={typeOfServiceOptions}
        direction="row"
        onChange={onChangeTypeOfService}
        className="anys-type-of-service__type-radio"
        disabled={disabled}
      />
      {isOffline ? (
        <div
          className={classNames(
            'anys-type-of-service__location',
            'anys-type-of-service__location--offline',
          )}
        >
          <SelectField
            name="typeOfService.locationType"
            className="anys-type-of-service__field"
            label={messages.locationType}
            options={locationTypeOptions}
            defaultValue={JOB_TYPE_OF_SERVICE_LOCATION_TYPE.ONE_SPOT}
            onChange={onChangeLocationType}
            disabled={disabled}
          />
          <div className="anys-type-of-service__location">
            <div className="anys-type-of-service__location__input-wrapper">
              {isDirection && (
                <TimelineLinePart
                  className={classNames(
                    'anys-type-of-service__location__line',
                    'anys-type-of-service__location__line--first',
                  )}
                  isVertical
                  hasLastDot={false}
                />
              )}
              <InputAutocompleteLocationField
                key={firstLocationValidation}
                type="text"
                name="typeOfService.startLocation"
                label={firstLocationLabel}
                placeholder={firstLocationPlaceholder}
                className={classNames(
                  'anys-type-of-service__location__input-wrapper__input',
                  {
                    'anys-type-of-service__field': isDirection,
                  },
                )}
                validate={
                  withValidation
                    ? formValidators.required(firstLocationValidation)
                    : undefined
                }
                disabled={disabled}
                withBounds={withBounds}
                withPlaceId={withPlaceId}
                withFullAddress={withFullAddress}
              />

              {!isOneSpot && !isDirection && (
                <InputField
                  className="anys-type-of-service__location__input-wrapper__radius"
                  name="typeOfService.area"
                  label={messages.radius}
                  placeholder={messages.radiusPlaceholder}
                  type="number"
                  isPureNumberInput
                  step={0.1}
                  validate={
                    withValidation
                      ? formValidators.composeValidators(
                          formValidators.required(messages.valueRequired),
                          formValidators.minValue(
                            t('General.atLeastValidation', {
                              min: MIN_AREA_FT,
                            }),
                            MIN_AREA_FT,
                            true,
                          ),
                          formValidators.maxValue(
                            t('General.atMostValidation', { max: MAX_AREA_FT }),
                            MAX_AREA_FT,
                            true,
                          ),
                        )
                      : undefined
                  }
                  min={0}
                  parse={(v) => (v ? (Number.parseFloat(v) as any) : v)}
                />
              )}
            </div>

            {isDirection && (
              <div className="anys-type-of-service__location__input-wrapper">
                <TimelineLinePart
                  hasFirstDot={false}
                  isLastDotLarge
                  className={classNames(
                    'anys-type-of-service__location__line',
                    'anys-type-of-service__location__line--last',
                  )}
                  isVertical
                />
                <InputAutocompleteLocationField
                  type="text"
                  name="typeOfService.endLocation"
                  label={messages.endLocation}
                  placeholder={messages.locationPlaceholder}
                  className="anys-type-of-service__location__input-wrapper__input"
                  validate={
                    withValidation
                      ? formValidators.required(messages.endLocationRequired)
                      : undefined
                  }
                  disabled={disabled}
                  withBounds={withBounds}
                  withPlaceId={withPlaceId}
                  withFullAddress={withFullAddress}
                />
              </div>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default TypeOfService;
