import React, {
  useCallback,
  useMemo,
  useRef,
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import { Job } from 'models/Job';
import { useTranslation } from 'react-i18next';
import { Button, Loader } from 'ncoded-component-library';
import { useLocation, useNavigate } from 'react-router-dom';
import JobPreviewPrice from 'components/JobPreviewPrice';
import StickyBottomContent from 'components/StickyBottomContent';
import Notice from 'components/Notice';
import InfoCircleIcon from 'icons/InfoCircle.icon';
import useInboxLink from 'router/subrouters/Inbox/hooks/useInboxLink';
import confirm, { ConfirmModalProps } from 'modules/confirm';

import './JobPreviewActions.styles.scss';
import './JobPreviewActions.styles.responsive.scss';
import { isPendingChanges } from 'utils/job-changes';
import { DropdownOption } from 'components/DropdownOptions/DropdownOptions.component';
import { DropdownRef } from 'ncoded-component-library/build/components/molecules/Dropdown/Dropdown.component';
import DropdownOptions from 'components/DropdownOptions';
import ElipsisIcon from 'icons/Elepsis.icon';

type JobPreviewActionsProps = {
  className?: string;
  jobCommonId: number;
  isCurrentUserClient: boolean;
  hasClientSigned: boolean;
  hasProviderSigned: boolean;
  inProgress?: boolean;
  isProvideJob: boolean;
  isOwnJob: boolean;
  job: Job;
  onSign: () => void;
  rejectChanges: () => Promise<void>;
};

const JobPreviewActions: React.FC<JobPreviewActionsProps> = (props) => {
  const {
    className,
    jobCommonId,
    isCurrentUserClient,
    hasClientSigned,
    hasProviderSigned,
    inProgress,
    isProvideJob,
    isOwnJob,
    job,
    rejectChanges,
    onSign,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();

  const { createEntityLink } = useInboxLink();

  const { versionState, isNegotiable, prevVersion, versionSubmitedBy } =
    job || {};

  const isInbox = location.pathname?.startsWith('/inbox');

  const [isSingleItem, setIsSingleItem] = useState(false);

  const classes = classNames('anys-job-preview-actions', className);

  const messages = useMemo(
    () => ({
      sign: t('General.sign'),
      change: t('General.change'),
      unsignAndChange: t('General.unsignAndChange'),
      reserve: t('General.reserve'),
      takeJob: t('General.takeJob'),
      client: t('General.client'),
      provider: t('General.provider'),
      rejectChanges: t('General.rejectChanges'),
    }),
    [t],
  );

  const hasSigned = isCurrentUserClient ? hasClientSigned : hasProviderSigned;
  const isClientSignPending = hasProviderSigned && !hasClientSigned;
  const isProviderSignPending = hasClientSigned && !hasProviderSigned;

  const hasClientMadeChanges = versionSubmitedBy === 'Client';
  const hasProviderMadeChanges = versionSubmitedBy === 'Provider';

  const showClientMadeChanges =
    // prevVersion?.versionSubmitedBy === 'Provider' &&
    prevVersion && isPendingChanges(versionState) && hasClientMadeChanges;

  const showProviderMadeChanges =
    // prevVersion?.versionSubmitedBy === 'Client' &&
    prevVersion && isPendingChanges(versionState) && hasProviderMadeChanges;

  const providerRequestedChanges = t('General.roleRequestedChanges', {
    role: messages.provider,
  });
  const clientRequestedChanges = t('General.roleRequestedChanges', {
    role: messages.client,
  });

  const youMadeChanges = t('General.youRequestedChanges', {
    otherPartyRole: hasClientMadeChanges
      ? messages.provider.toLowerCase()
      : messages.client.toLowerCase(),
  });

  const offerSent = t('General.offerSentToRole', {
    role: isCurrentUserClient
      ? messages.provider.toLowerCase()
      : messages.client.toLowerCase(),
  });

  const noticeMessage = useMemo(() => {
    if (!isCurrentUserClient && showClientMadeChanges)
      return clientRequestedChanges;

    if (isCurrentUserClient && showProviderMadeChanges)
      return providerRequestedChanges;

    if (
      (isCurrentUserClient && showClientMadeChanges) ||
      (!isCurrentUserClient && showProviderMadeChanges)
    )
      return youMadeChanges;

    if (
      (isProvideJob && isCurrentUserClient && hasClientSigned) ||
      (!isProvideJob && !isCurrentUserClient && hasProviderSigned)
    )
      return offerSent;

    return null;
  }, [
    clientRequestedChanges,
    hasClientSigned,
    hasProviderSigned,
    isCurrentUserClient,
    isProvideJob,
    offerSent,
    providerRequestedChanges,
    showClientMadeChanges,
    showProviderMadeChanges,
    youMadeChanges,
  ]);

  const jobLink = isInbox
    ? createEntityLink('edit', 'job', jobCommonId)
    : `/job/edit/${jobCommonId}`;

  const actionContent = hasSigned
    ? messages.unsignAndChange
    : isClientSignPending
      ? messages.reserve
      : isProviderSignPending
        ? messages.takeJob
        : messages.sign;

  const canShowRejectVersion =
    prevVersion &&
    ((isCurrentUserClient && showProviderMadeChanges) ||
      (!isCurrentUserClient && showClientMadeChanges));

  const toggleSign = useCallback(
    //todo: check do we need conf modal on unsign now
    async (actionName?: string) => {
      onSign();
    },
    [onSign],
  );

  const changeJob = useCallback(async () => {
    if (isNegotiable || isOwnJob) {
      navigate(jobLink);
    } else {
      const entityName = t(
        hasClientSigned || hasProviderSigned
          ? 'General.offer'
          : 'General.preOffer',
      );

      const actions: ConfirmModalProps = hasSigned
        ? {
            confirmContent: t('General.unsign'),
            cancelContent: t('General.cancel'),
            description: t('General.youCanUnsignEntity', {
              entity: entityName.toLowerCase(),
            }),
            onConfirm: () => toggleSign(t('General.unsign')),
          }
        : {
            cancelContent: null,
          };

      await confirm({
        title: t('General.entityNonNegotiable', {
          entity: entityName,
        }),
        ...actions,
      });
    }
  }, [
    hasClientSigned,
    hasProviderSigned,
    hasSigned,
    isNegotiable,
    isOwnJob,
    jobLink,
    navigate,
    t,
    toggleSign,
  ]);

  const onPrimaryAction = useCallback(async () => {
    if (hasSigned) {
      changeJob();

      return;
    }

    await toggleSign();
  }, [changeJob, hasSigned, toggleSign]);

  const addByCondition = (condition: boolean, item: DropdownOption) => {
    return condition ? [item] : [];
  };

  const dropdownRef = useRef<DropdownRef>();

  const dropDownOptions = useMemo(
    () => [
      ...addByCondition(!hasSigned, {
        title: messages.change,
        key: messages.change,
        onClick: async () => {
          ((dropdownRef as any).current as any)?.setIsOpen(false);
          await changeJob();
        },
      }),
      ...addByCondition(canShowRejectVersion, {
        title: messages.rejectChanges,
        key: messages.rejectChanges,
        onClick: async () => {
          ((dropdownRef as any).current as any)?.setIsOpen(false);
          await rejectChanges();
        },
        disabled: inProgress,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasSigned, canShowRejectVersion, inProgress],
  );

  useEffect(() => {
    if (dropDownOptions.length === 1) {
      setIsSingleItem(true);
    }
  }, [dropDownOptions]);

  const ActionsDropDown = (
    <DropdownOptions
      className={`actions-dropdown ${isSingleItem ? 'dropdown--single-item' : ''}`}
      dropdownRef={dropdownRef}
      lockScroll={false}
      trigger={
        <Button
          className="actions-dropdown trigger-button"
          variant="link"
          icon={<ElipsisIcon />}
        />
      }
      options={dropDownOptions}
    />
  );

  return (
    <StickyBottomContent className={classes}>
      {noticeMessage ? (
        <Notice className="anys-job-preview-actions__notice">
          <InfoCircleIcon /> {noticeMessage}
        </Notice>
      ) : null}

      <div className="anys-job-preview-actions__bottom">
        <div className="anys-job-preview-actions__bottom__buttons">
          <Button
            type="button"
            variant={hasSigned ? 'outline' : 'solid'}
            onClick={onPrimaryAction}
            disabled={inProgress}
          >
            {inProgress ? <Loader inline overlay={false} /> : actionContent}
          </Button>
        </div>
        {dropDownOptions.length > 0 ? ActionsDropDown : null}
        <JobPreviewPrice
          job={job}
          isOffer={job?.isSignedByClient || job?.isSignedByProvider}
        />
      </div>
    </StickyBottomContent>
  );
};

export default JobPreviewActions;
